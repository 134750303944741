import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth/useAuth';

import { QRouteProps } from './Route.types';

export const PrivateRoute = ({ isPublic = false, children }: QRouteProps) => {
  const { accessToken } = useAuth();

  if (!isPublic && !accessToken) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
