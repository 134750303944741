import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from 'styled-components';

import { useSingleMarker } from '../map/hooks/useSingleMarker/useSingleMarker';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { ClusterIcon } from '../ClusterIcon';

import { MarkerProps } from './Marker.types';
import { Styled } from './Marker.styles';
import surface from './icons/surface.svg';
import room from './icons/room.svg';
import { CloseIcon } from './components/CloseIcon';
import { formatNumber } from './Marker.utils';

export const Marker = ({ cluster }: MarkerProps) => {
  const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
  const fromMobile: unknown = useMediaQuery(useTheme()?.breakpoints.to('xs'));

  const {
    data: markerData,
    isFetching: isMarkerFetch,
    refetch,
  } = useSingleMarker(
    {
      id: cluster?.properties.markerId,
    },
    {
      enabled: false,
    },
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (isPopoverOpen) refetch();
  }, [isPopoverOpen]);

  const handleClick = () => {
    window.open(`${window.location?.origin}/investment/details/${cluster.properties.markerId}`, '_blank', 'noreferrer');
  };
  return (
    <Styled.CustomMarkerPopover
      isOpen={isPopoverOpen}
      positions={['top']}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      //@ts-ignore
      ref={clickMeButtonRef}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <Styled.ContentWrapper id="marker-wrapper">
            {!isMarkerFetch && (
              <>
                <Styled.CloseIconWrapper onClick={() => setIsPopoverOpen(false)}>
                  <CloseIcon />
                </Styled.CloseIconWrapper>
                <Styled.ContentWrapperImage id="image-wrapper">
                  <img
                    src={`${markerData?.photoUrl}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${markerData?.photoUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt=""
                    loading="lazy"
                  />
                </Styled.ContentWrapperImage>
                <Styled.ContentWrapperDescription id="content-wrapper">
                  <Styled.ContentWrapperDescriptionWrapper>
                    {fromMobile && (
                      <Styled.ContentWrapperDescriptionDeveloper>
                        {markerData?.location}, {markerData?.developerName}
                      </Styled.ContentWrapperDescriptionDeveloper>
                    )}
                    {!fromMobile && (
                      <Styled.ContentWrapperDescriptionDeveloper>
                        {markerData?.location}
                      </Styled.ContentWrapperDescriptionDeveloper>
                    )}
                    {!fromMobile && (
                      <Styled.ContentWrapperDescriptionDeveloper>
                        {markerData?.developerName}
                      </Styled.ContentWrapperDescriptionDeveloper>
                    )}
                    <Styled.ContentWrapperDescriptionTitle>
                      {markerData?.investmentName}
                    </Styled.ContentWrapperDescriptionTitle>
                    <Styled.ContentWrapperDescriptionPriceRange>
                      {`${markerData?.priceMin.toLocaleString()} - ${markerData?.priceMax.toLocaleString()} ${
                        markerData?.currency
                      }`}
                    </Styled.ContentWrapperDescriptionPriceRange>
                    <Styled.ContentWrapperDescriptionUnitPrice>
                      {`${markerData?.pricePerMeterMin.toLocaleString()} - ${markerData?.pricePerMeterMax.toLocaleString()} ${
                        markerData?.currency
                      }/m²`}
                    </Styled.ContentWrapperDescriptionUnitPrice>
                    <Styled.ContentWrapperDescriptionInfo>
                      {markerData?.areaMin && markerData?.areaMax && (
                        <Styled.ContentWrapperDescriptionInfoSingle>
                          <img src={surface} alt="" />
                          <p>{`${markerData?.areaMin}-${markerData?.areaMax} m²`}</p>
                        </Styled.ContentWrapperDescriptionInfoSingle>
                      )}
                      {markerData?.roomMin && markerData?.roomMax && (
                        <Styled.ContentWrapperDescriptionInfoSingle>
                          <img src={room} alt="" />
                          <p>{`${markerData?.roomMin}-${markerData?.roomMax} pok.`}</p>
                        </Styled.ContentWrapperDescriptionInfoSingle>
                      )}
                    </Styled.ContentWrapperDescriptionInfo>

                    <Styled.ContentWrapperDescriptionButton variant="outlined" onClick={() => handleClick()}>
                      Szczegóły
                    </Styled.ContentWrapperDescriptionButton>
                  </Styled.ContentWrapperDescriptionWrapper>
                </Styled.ContentWrapperDescription>
              </>
            )}
            {isMarkerFetch && (
              <Stack>
                <Skeleton variant="rounded" width={578} height={230} />
              </Stack>
            )}
          </Styled.ContentWrapper>
        </ArrowContainer>
      )}
    >
      <Styled.CustomMarker onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <ClusterIcon />
        <Styled.CustomMarkerPin>
          <span>{formatNumber(cluster?.properties.priceMin)}</span>
          <span>-</span>
          <span>{formatNumber(cluster?.properties.priceMax)}</span>
        </Styled.CustomMarkerPin>
      </Styled.CustomMarker>
    </Styled.CustomMarkerPopover>
  );
};
