import React from 'react';
import { useTheme } from 'styled-components';

import { InfoButton } from 'components/infoButton/InfoButton';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { SkeletonWrapper } from 'app/googleMap/components/skeletonWrapper/SkeletonWrapper';

import { PointDetailsProps } from './PointDetails.types';
import { Styled } from './PointDetails.styles';
import { TopDescription } from './components/topDescription/TopDescription';
import { InvestmentName } from './components/investmentName/InvestmentName';
import { ImageSlider } from './components/imageSlider/ImageSlider';
import { InvestmentDetails } from './components/investmentDetails/InvestmentDetails';
import { InvestmentTableContainer } from './components/investmentTable/InvestmentTableContainer';

const msg =
  'Dane dotyczące lokali przedstawiają możliwie najbardziej aktualne wartości uzyskane przez usługodawcę w ramach współpracy z podmiotami odpowiedzialnymi za ich sprzedaż. Szczegóły w <a href="https://propone.pl/policies/terms-of-service" style="color:black;" target="_blank">Regulaminie</a>. Powyższe informacje nie stanowią oferty handlowej w rozumieniu art. 66 § 1 Kodeksu Cywilnego.';

export const PointDetails = ({ details, offerId, isFetching }: PointDetailsProps) => {
  const fromMobile = useMediaQuery(useTheme()?.breakpoints.from('sm'));
  return (
    <Styled.InvestmentWrapper>
      <TopDescription
        developerUrl={String(details?.developerUrl)}
        developerName={String(details?.developerName)}
        investmentEnd={String(details?.investmentEnd)}
      />
      <Styled.InvestmentInfoWrapper>
        <Styled.InvestmentInfoCol>
          <InvestmentName investmentName={String(details?.investmentName)} />
          <Styled.InvestmentLocation>{details?.location}</Styled.InvestmentLocation>
        </Styled.InvestmentInfoCol>
        <Styled.InvestmentEndMobile>{details?.investmentEnd}</Styled.InvestmentEndMobile>
        <Styled.InvestmentInfoCol textAlignEnd>
          <Styled.InvestmentInfoDetails>
            <Styled.InvestmentInfoDetailsArea>
              <span>{details?.areaMin} - </span>
              <span>
                {details?.areaMax} m<sup>2</sup>
              </span>
            </Styled.InvestmentInfoDetailsArea>
            <Styled.InvestmentInfoDetailsSmall>
              od {details?.pricePerMeterMin.toLocaleString()} zł/m<sup>2</sup> <InfoButton msg={msg} />
            </Styled.InvestmentInfoDetailsSmall>
          </Styled.InvestmentInfoDetails>
          <Styled.InvestmentInfoDetails margin>
            <Styled.InvestmentInfoDetailsArea>
              <span>{details?.priceMin.toLocaleString()} - </span>
              <span>{details?.priceMax.toLocaleString()} zł</span>
            </Styled.InvestmentInfoDetailsArea>
            <Styled.InvestmentInfoDetailsSmall>
              <a href="https://propone.pl/pages/kredyty-hipoteczne" target="_blank" rel="noreferrer">
                {fromMobile ? 'Sprawdź kredyt hipoteczny' : 'Sprawdź kredyt'}
              </a>
            </Styled.InvestmentInfoDetailsSmall>
          </Styled.InvestmentInfoDetails>
        </Styled.InvestmentInfoCol>
      </Styled.InvestmentInfoWrapper>
      <ImageSlider photoUrls={details?.photoUrls} />
      <InvestmentDetails details={details} />
      <InvestmentTableContainer id={offerId} />
      {isFetching && <SkeletonWrapper />}
    </Styled.InvestmentWrapper>
  );
};
