import React from 'react';

import { Button } from 'components/button/Button';
import { Search } from 'app/googleMap/components/search/Search';
import { ResetButton } from 'components/resetButton/ResetButton';

import { Styled } from './FiltersWrapper.styles';
import { FiltersWrapperProps } from './FiltersWrapper.types';

export const FiltersWrapper = ({ data, buttonTitle, onButtonClick, onResetButtonClick }: FiltersWrapperProps) => {
  return (
    <>
      <Styled.FiltersWrapper data-testid="filters-wrapper">
        <Styled.FiltersWrapperCol>
          <Search label={data.startPrice.label} value={data.startPrice.value} onChange={data.startPrice.onChange} />
          <span>-</span>
          <Search label={data.endPrice.label} value={data.endPrice.value} onChange={data.endPrice.onChange} />
        </Styled.FiltersWrapperCol>
        <Styled.FiltersWrapperCol>
          <Search label={data.startSquare.label} value={data.startSquare.value} onChange={data.startSquare.onChange} />
          <span>-</span>
          <Search label={data.endSquare.label} value={data.endSquare.value} onChange={data.endSquare.onChange} />
        </Styled.FiltersWrapperCol>
        <Styled.FiltersWrapperCol>
          <Search label={data.startRoom.label} value={data.startRoom.value} onChange={data.startRoom.onChange} />
          <span>-</span>
          <Search label={data.endRoom.label} value={data.endRoom.value} onChange={data.endRoom.onChange} />
        </Styled.FiltersWrapperCol>
        <Styled.FiltersWrapperCol>
          <Search label={data.startFloor.label} value={data.startFloor.value} onChange={data.startFloor.onChange} />
          <span>-</span>
          <Search label={data.endFloor.label} value={data.endFloor.value} onChange={data.endFloor.onChange} />
        </Styled.FiltersWrapperCol>
        <Styled.FiltersWrapperCol hideMobile></Styled.FiltersWrapperCol>
        <Styled.FiltersWrapperCol>
          <ResetButton title="Resetuj" onClick={onResetButtonClick} testId="button-reset" />
          <Button title={buttonTitle} onClick={onButtonClick} testId="button-accept" />
        </Styled.FiltersWrapperCol>
      </Styled.FiltersWrapper>
    </>
  );
};
