import { createServer } from 'miragejs';

import { SERVER_RESPONSE_TIME_MS } from './config';
import { useAppApiRoutes } from './routes';

export const runMockServerWhenEnabled = (isForced?: boolean) => {
  const mockServerEnabled = process.env.APP_API_MOCK || isForced;

  if (!mockServerEnabled) {
    return;
  }

  createServer({
    routes() {
      this.timing = SERVER_RESPONSE_TIME_MS;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAppApiRoutes(this);
    },
  });
};
