import styled, { css } from 'styled-components';

const InvestmentTableWrapper = styled.div(
  () => css`
    max-width: 952px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  `,
);

InvestmentTableWrapper.displayName = 'InvestmentTable--Wrapper';

const InvestmentTableContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 60px;
    max-width: 952px;
    ${theme.breakpoints.from('xs')} {
      margin-top: 40px;
    }
    ${theme.breakpoints.from('sm')} {
      padding: 0 26px;
      width: initial;
    }

    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }

    .MuiTableCell-head {
      color: ${theme.colors.tableHeading};
      font-size: 12px;
      font-weight: 500;
      padding: 16px 0px;
      ${theme.breakpoints.from('xs')} {
        padding: 16px 3px;
      }
      ${theme.breakpoints.from('sm')} {
        padding: 11px;
        font-size: 14px;
      }
    }
    .MuiTableCell-body {
      color: ${theme.colors.tableHeading};
      font-size: 12px;
      padding: 16px 10px;
      ${theme.breakpoints.from('xs')} {
        padding: 16px 3px;
      }
      ${theme.breakpoints.from('sm')} {
        padding: 16px;
        font-size: 14px;
      }
    }
    .MuiTable-root {
      min-width: initial;
    }

    .MuiTableCell-root {
      border-bottom: 1px solid ${theme.colors.grey}!important;
      color: ${theme.colors.tableHeading};
      font-size: 12px;
      width: 16.66%;
      ${theme.breakpoints.from('sm')} {
        font-size: 14px;
        width: initial;
      }
    }
    .MuiPaper-root {
      box-shadow: none;
    }
  `,
);

InvestmentTableContainer.displayName = 'InvestmentTable--Container';

const InvestmentTableDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    padding: 0 26px;
    ${theme.breakpoints.from('sm')} {
      font-size: 13px;
      line-height: 20px;
    }
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }

    a {
      color: ${theme.colors.black};
    }
  `,
);

InvestmentTableDescription.displayName = 'InvestmentTable--Description';

const InvestmentTableEmpty = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.description};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    line-height: 26px;
    text-align: center;
    ${theme.breakpoints.from('xs')} {
      font-size: 14px;
      line-height: 30px;
    }
  `,
);

InvestmentTableEmpty.displayName = 'InvestmentTable--Empty';

const InvestmentTablePopoverContent = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 10px 10px;
    ${theme.breakpoints.from('xs')} {
      font-size: 14px;
      line-height: 30px;
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: bottom;
    }
  `,
);

InvestmentTablePopoverContent.displayName = 'InvestmentTablePopover--Content';

const InvestmentTablePopoverImageWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 10px 10px;
    width: 250px;
    height: 250px;
    ${theme.breakpoints.from('xs')} {
      font-size: 14px;
      line-height: 30px;
    }
  `,
);

InvestmentTablePopoverImageWrapper.displayName = 'InvestmentTablePopoverImage--Wrapper';

const InvestmentTableSvgWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  `,
);

InvestmentTableSvgWrapper.displayName = 'InvestmentTableSvg--Wrapper';

const InvestmentTableDownload = styled.div(
  () => css`
    display: block;
    margin: 5px 0;
    &:hover {
      cursor: pointer;
    }
  `,
);

InvestmentTableDownload.displayName = 'InvestmentTable--Download';

export const Styled = {
  InvestmentTableWrapper,
  InvestmentTableContainer,
  InvestmentTableDescription,
  InvestmentTableEmpty,
  InvestmentTablePopoverContent,
  InvestmentTablePopoverImageWrapper,
  InvestmentTableSvgWrapper,
  InvestmentTableDownload,
};
