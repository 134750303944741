export const formatNumber = (value: number | string) => {
  if (!value) return;
  if (String(value).length >= 7) {
    const sliceNumber = String(value).slice(0, 3);
    const splitNumber = sliceNumber.split('');
    const formatNumber =
      splitNumber[1] === '0' ? `${splitNumber[0]} mln` : `${sliceNumber[0]},${sliceNumber[1]}${sliceNumber[2]} mln`;
    return formatNumber;
  }

  if (String(value).length <= 6) {
    return `${String(value).slice(0, 3)} tys`;
  }

  return value;
};
