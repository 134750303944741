import React from 'react';
import { Alert, Snackbar as MuiSnackbar } from '@mui/material';

import { SnackbarProps } from './Snackbar.types';

export const Snackbar: React.FC<SnackbarProps> = ({ message, severity, open, onClose }) => {
  return (
    <MuiSnackbar open={open} autoHideDuration={7000} onClose={onClose}>
      <Alert severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
