import React from 'react';

import { TopDescriptionProps } from './TopDescription.types';
import { Styled } from './TopDescription.styles';
import { BackIcon } from './BackIcon';

export const TopDescription = ({ developerUrl, developerName, investmentEnd }: TopDescriptionProps) => {
  const handleClick = () => {
    window.open(`${window.location?.origin}/map`, '_self', 'noreferrer');
  };

  return (
    <>
      <Styled.InvestmentBack onClick={() => handleClick()}>
        <BackIcon /> Cofnij
      </Styled.InvestmentBack>
      <Styled.TopDescriptionWrapper>
        <Styled.TopDescriptionDeveloper>
          Nowe mieszkania na sprzedaż od <span>{developerName}</span>
        </Styled.TopDescriptionDeveloper>
        <Styled.InvestmentEnd>{investmentEnd}</Styled.InvestmentEnd>
      </Styled.TopDescriptionWrapper>
    </>
  );
};
