import styled, { css } from 'styled-components';

const SkeletonWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    top: 0;
    bottom: 0;

    color: ${theme.colors.white};
    background: rgba(255, 255, 255, 0.4);
  `,
);

SkeletonWrapper.displayName = 'Skeleton--Wrapper';

export const Styled = {
  SkeletonWrapper,
};
