import { Registry, Response, Server } from 'miragejs';
import type { AnyFactories, AnyModels } from 'miragejs/-types';

import { markers } from 'mocks/fixtures/markers';

type MockServerType = Server<Registry<AnyModels, AnyFactories>>;

export const createAppRoutes = (api: string) => (server: MockServerType) => {
  server.get(`${api}/markers`, () => markers);
  server.get(`${api}/location-details`, () => markers);

  // return 404 errors for unhandled requests
  server.get(`${api}/**`, () => new Response(404));
  server.post(`${api}/**`, () => new Response(404));
  server.put(`${api}/**`, () => new Response(404));
  server.patch(`${api}/**`, () => new Response(404));
  server.del(`${api}/**`, () => new Response(404));
};

const pomApi = process.env.REACT_APP_API_URL;

export const useAppApiRoutes = (server: MockServerType) => {
  createAppRoutes(pomApi)(server);
};
