import styled, { css } from 'styled-components';

const InvestmentWrapper = styled.div(
  ({ theme }) => css`
    max-width: 1276px;
    margin: 0 auto;
  `,
);

InvestmentWrapper.displayName = 'Investment--Wrapper';

const InvestmentInfoWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 0 26px;
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }
  `,
);

InvestmentInfoWrapper.displayName = 'InvestmentInfo--Wrapper';

const InvestmentLocation = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 12px 0 0 0;
    ${theme.breakpoints.from('sm')} {
      font-size: 24px;
      line-height: 34px;
      margin: 0;
    }
  `,
);

InvestmentLocation.displayName = 'Investment--Location';

const InvestmentEndMobile = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 12px;
    font-weight: 400;
    margin: 25px 0 5px 0;
    ${theme.breakpoints.from('sm')} {
      display: none;
    }
  `,
);

InvestmentEndMobile.displayName = 'InvestmentEnd--Mobile';

const InvestmentInfoCol = styled.div<{ textAlignEnd?: boolean }>(
  ({ theme, textAlignEnd }) => css`
    display: flex;
    width: 100%;
    align-items: baseline;
    flex-direction: ${textAlignEnd ? 'row' : 'column'};
    ${theme.breakpoints.from('md')} {
      width: 50%;
      text-align: ${textAlignEnd ? 'end' : 'initial'};
      flex-direction: ${textAlignEnd ? 'row' : 'column'};
      justify-content: ${textAlignEnd ? 'flex-end' : 'initial'};
    }
  `,
);

InvestmentInfoCol.displayName = 'InvestmentInfo--Col';

const InvestmentInfoDetails = styled.div<{ margin?: boolean }>(
  ({ theme, margin }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: ${margin ? '10px' : '0'};
    ${theme.breakpoints.from('sm')} {
      width: initial;
      margin-left: ${margin ? '31px' : '0'};
    }
  `,
);

InvestmentInfoDetails.displayName = 'InvestmentInfo--Details';

const InvestmentInfoDetailsArea = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    min-height: 53px;
    ${theme.breakpoints.from('sm')} {
      font-size: 28px;
      line-height: 42px;
      min-height: initial;
    }
    span {
      width: 100%;
      ${theme.breakpoints.from('sm')} {
        width: initial;
      }
    }

    sup {
      font-size: 14px;
      position: relative;
      top: -8px;
    }
  `,
);

InvestmentInfoDetailsArea.displayName = 'InvestmentInfoDetails--Area';

const InvestmentInfoDetailsSmall = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin: 6px 0 0 0;

    display: flex;

    ${theme.breakpoints.from('sm')} {
      justify-content: flex-end;
      font-size: 14px;
      line-height: 21px;
      margin: 0;
    }

    a {
      color: ${theme.colors.inputText};
    }
  `,
);

InvestmentInfoDetailsSmall.displayName = 'InvestmentInfoDetails--Small';

export const Styled = {
  InvestmentWrapper,
  InvestmentInfoWrapper,
  InvestmentLocation,
  InvestmentInfoCol,
  InvestmentInfoDetails,
  InvestmentInfoDetailsArea,
  InvestmentInfoDetailsSmall,
  InvestmentEndMobile,
};
