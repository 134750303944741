import React from 'react';

import { Styled } from './InvestmentName.styles';
import { InvestmentNameProps } from './InvestmentName.types';

export const InvestmentName = ({ investmentName }: InvestmentNameProps) => {
  return (
    <>
      <Styled.InvestmentName data-testid="investment-name">{investmentName}</Styled.InvestmentName>
    </>
  );
};
