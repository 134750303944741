import React from 'react';

import { useParams } from 'hooks/useParams/useParams';
import { AppRoute } from 'routing/AppRoute.enum';

import { useDetails } from './hooks/useDetails';
import { PointDetails } from './PointDetails';

export const PointDetailsContainer = () => {
  const params = useParams<AppRoute.pointDetails>();

  const { data: offerDetails, isFetching } = useDetails({
    id: params?.id,
  });

  return (
    <>{offerDetails ? <PointDetails details={offerDetails} offerId={params?.id} isFetching={isFetching} /> : null}</>
  );
};
