/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { StringParam } from 'use-query-params';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useToast } from 'hooks/useToast/useToast';

import { InvestmentTable } from './InvestmentTable';
import { useInvestment } from './hooks/useInvestment/useInvestment';
import { InvestmentTableWrapperProps } from './InvestmentTable.types';
import { useFilters } from './hooks/useFilters/useFilters';
import { FiltersWrapper } from './filtersWrapper/FiltersWrapper';
import { Styled } from './InvestmentTable.styles';

export const mapParams = {
  id: StringParam,
  startPrice: StringParam,
  endPrice: StringParam,
  startSquare: StringParam,
  endSquare: StringParam,
  startRoom: StringParam,
  endRoom: StringParam,
  startFloor: StringParam,
  endFloor: StringParam,
};

export const InvestmentTableContainer = ({ id }: InvestmentTableWrapperProps) => {
  const [offersData, setOffersData] = useState([]);
  const [reset, setReset] = useState(false);
  const {
    offerId,
    handleOfferIdChange,
    startPrice,
    handleStartPriceChange,
    endPrice,
    handleEndPriceChange,
    startSquare,
    handleStartSquareChange,
    endSquare,
    handleEndSquareChange,
    startRoom,
    handleStartRoomChange,
    endRoom,
    handleEndRoomChange,
    startFloor,
    handleStartFloorChange,
    endFloor,
    handleEndFloorChange,
    handleReset,
  } = useFilters();

  const { showSnackBar } = useToast();

  const { refetch } = useInvestment(
    {
      id: id || offerId,
      startPrice,
      endPrice,
      startSquare,
      endSquare,
      startRoom,
      endRoom,
      startFloor,
      endFloor,
    },
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (res: any) => {
        setOffersData(res?.data);
      },
      onError: () => {
        showSnackBar('Wystąpił błąd podczas pobierania danych', 'error');
      },
    },
  );

  useEffect(() => {
    handleOfferIdChange(id);
    refetch();
  }, [id]);

  useEffect(() => {
    refetch();
    setReset(false);
  }, [reset]);

  const { t } = useLocale();

  const data = {
    startPrice: {
      label: t('CORE.FILTERS.START_PRICE'),
      value: startPrice,
      onChange: handleStartPriceChange,
    },
    endPrice: {
      label: t('CORE.FILTERS.END_PRICE'),
      value: endPrice,
      onChange: handleEndPriceChange,
    },
    startSquare: {
      label: t('CORE.FILTERS.FROM'),
      value: startSquare,
      onChange: handleStartSquareChange,
    },
    endSquare: {
      label: t('CORE.FILTERS.DO'),
      value: endSquare,
      onChange: handleEndSquareChange,
    },
    startRoom: {
      label: t('CORE.FILTERS.ROOM.FROM'),
      value: startRoom,
      onChange: handleStartRoomChange,
    },
    endRoom: {
      label: t('CORE.FILTERS.ROOM.TO'),
      value: endRoom,
      onChange: handleEndRoomChange,
    },
    startFloor: {
      label: t('CORE.FILTERS.FLOOR.FROM'),
      value: startFloor,
      onChange: handleStartFloorChange,
    },
    endFloor: {
      label: t('CORE.FILTERS.FLOOR.TO'),
      value: endFloor,
      onChange: handleEndFloorChange,
    },
  };

  const handleSearchButtonClick = () => {
    refetch();
  };

  const handleResetButtonClick = () => {
    handleReset();
    setReset(true);
  };

  return (
    <>
      <Styled.InvestmentTableWrapper>
        <FiltersWrapper
          data={data}
          buttonTitle={t('CORE.FILTERS.FIND')}
          onButtonClick={handleSearchButtonClick}
          onResetButtonClick={handleResetButtonClick}
        />
        <InvestmentTable data={offersData} />
      </Styled.InvestmentTableWrapper>
    </>
  );
};
