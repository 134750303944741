import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import { Styled } from './SkeletonWrapper.styles';

export const SkeletonWrapper = () => {
  return (
    <Styled.SkeletonWrapper>
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </Styled.SkeletonWrapper>
  );
};
