import axios from 'axios';

import { validateStatus } from './validateStatus';

export const createApiInstance = (baseUrl: string) => {
  return axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    validateStatus,
  });
};
