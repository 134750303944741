import styled, { css } from 'styled-components';

const FiltersWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 15px 10px;
    align-items: center;
    justify-content: space-between;
    max-width: 952px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 26px;
    ${theme.breakpoints.from('md')} {
    }
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }
  `,
);

FiltersWrapper.displayName = 'FiltersWrapper--Wrapper';

const FiltersWrapperCol = styled.div<{ hideMobile?: boolean }>(
  ({ theme, hideMobile }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    display: ${hideMobile ? `none` : 'flex'};
    ${theme.breakpoints.from('xs')} {
      width: 32.1%;
      display: ${hideMobile ? `flex` : 'flex'};
    }
    ${theme.breakpoints.from('sm')} {
      width: 32.4%;
    }
    ${theme.breakpoints.from('md')} {
      width: 288px;
    }

    ${theme.breakpoints.from('lg')} {
      width: 304px;
    }

    span {
      margin: 0 5px;
    }
    .MuiFormControl-root {
      width: 49%;
      ${theme.breakpoints.from('xs')} {
        width: 142px;
      }
    }
    .MuiButtonBase-root {
      width: 48%;
      ${theme.breakpoints.from('md')} {
        width: 142px;
      }
    }
  `,
);

FiltersWrapperCol.displayName = 'FiltersWrapper--Col';

export const Styled = {
  FiltersWrapper,
  FiltersWrapperCol,
};
