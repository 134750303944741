import React from 'react';
import Popover from '@mui/material/Popover';

import { Styled } from '../investmentTable/InvestmentTable.styles';
import { DownloadIcon } from '../investmentTable/downloadIcon/DownloadIcon';

import { InvestmentPopoverProps } from './InvestmentPopover.types';

export const InvestmentPopover = ({ floorPlanUrls, handleOpen }: InvestmentPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const downloadImages = (images: string[]) => {
    if (!images.length) return;

    images.map((image, index) => {
      setTimeout(() => {
        fetch(image)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = `floor-${index}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      }, index * 1000);
    });
  };
  return (
    <>
      <Styled.InvestmentTableSvgWrapper
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-describedby={id}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => handleOpen(floorPlanUrls)}
      >
        <DownloadIcon />
      </Styled.InvestmentTableSvgWrapper>
      <Styled.InvestmentTableDownload onClick={() => downloadImages(floorPlanUrls)}>
        Pobierz
      </Styled.InvestmentTableDownload>
      <Popover
        id={id}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
      >
        <Styled.InvestmentTablePopoverContent>
          {floorPlanUrls.length &&
            floorPlanUrls.slice(0, 2).map((image, index) => {
              return (
                <Styled.InvestmentTablePopoverImageWrapper key={index}>
                  <img
                    loading="lazy"
                    src={`${image}?w=952&h=418&fit=crop&auto=format`}
                    srcSet={`${image}?w=952&h=418&fit=crop&auto=format&dpr=2 2x`}
                    data-testid={`image-${index}`}
                  />
                </Styled.InvestmentTablePopoverImageWrapper>
              );
            })}
        </Styled.InvestmentTablePopoverContent>
      </Popover>
    </>
  );
};
