import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';

import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ApiClientContextController } from 'context/apiClient/apiClientContextController/ApiClientContextController';
import { ToastContextController } from 'context/toast/toastContextController/ToastContextController';
import { Cookie } from 'components/Cookie/Cookie';

import { ThemeProvider } from './theme/ThemeProvider';
import { ApiProvider } from './api/ApiProvider';
import { AppProvidersProps } from './AppProviders.types';
import { I18nProvider } from './i18n/I18nProvider';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 5px 10px #00000062',
          width: '268px',
          color: '#000000',
          fontSize: '10px',
          padding: '12px',
          lineHeight: '16px',
        },
      },
    },
  },
});

export const AppProviders = ({ children }: AppProvidersProps) => (
  <I18nProvider>
    <ToastContextController>
      <CookiesProvider>
        <Router>
          <ApiClientContextController>
            <ApiProvider>
              <AuthContextController>
                {/* <RouterProvider>{children}</RouterProvider> */}
                <ThemeProvider>
                  <MuiThemeProvider theme={theme}>
                    {children}
                    <Cookie />
                  </MuiThemeProvider>
                </ThemeProvider>
              </AuthContextController>
            </ApiProvider>
          </ApiClientContextController>
        </Router>
      </CookiesProvider>
    </ToastContextController>
  </I18nProvider>
);
