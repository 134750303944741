import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from 'components/button/Button';
import { Search } from '../search/Search';
import { Select } from '../select/Select';
import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { ResetButton } from 'components/resetButton/ResetButton';
import { BigSearch } from '../bigSearch/BigSearch';

import { Styled } from './FiltersWrapper.styles';
import { FiltersWrapperProps } from './FiltersWrapper.types';

export const FiltersWrapper = ({ data, buttonTitle, onButtonClick }: FiltersWrapperProps) => {
  const fromMobile = useMediaQuery(useTheme()?.breakpoints.from('sm'));

  return (
    <>
      <Styled.FiltersWrapper data-testid="filters-wrapper">
        <BigSearch label={data.location.label} value={data.location.value} onChange={data.location.onChange} />
        <Styled.FiltersWrapperMenuCol>
          <Search label={data.startPrice.label} value={data.startPrice.value} onChange={data.startPrice.onChange} />
          <span>-</span>
          <Search label={data.endPrice.label} value={data.endPrice.value} onChange={data.endPrice.onChange} />
        </Styled.FiltersWrapperMenuCol>
        <Styled.FiltersWrapperMenuCol>
          <Search label={data.startSquare.label} value={data.startSquare.value} onChange={data.startSquare.onChange} />
          <span>-</span>
          <Search label={data.endSquare.label} value={data.endSquare.value} onChange={data.endSquare.onChange} />
        </Styled.FiltersWrapperMenuCol>
        <Select
          label={data.termId.label}
          value={data.termId.value}
          onChange={data.termId.onChange}
          options={data.termId.options}
        />
        <Styled.FiltersWrapperMenuCol>
          {!fromMobile && <ResetButton title="Resetuj" onClick={onButtonClick} />}
          <Button title={buttonTitle} onClick={onButtonClick} data-testid="button-accept" />
        </Styled.FiltersWrapperMenuCol>
      </Styled.FiltersWrapper>
    </>
  );
};
