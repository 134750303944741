/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@mui/material/Box';

import { useLocale } from 'hooks/useLocale/useLocale';

import { Styled } from './Login.styles';
import { LoginProps } from './Login.types';

export const Login = ({ register, handleSubmit, onFormSubmit, errors }: LoginProps) => {
  const { t } = useLocale();
  const handleClick = () => {
    window.open(`https://propone.pl/pages/mapa-inwestycji`, '_blank', 'noreferrer');
  };
  return (
    <>
      <Styled.LoginWrapper>
        <Styled.LoginTitle>Mapa mieszkań od deweloperów z cenami w promocjach</Styled.LoginTitle>
        <Styled.LoginForm>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onFormSubmit)}>
            <Styled.Input
              required
              label="Email"
              type="email"
              {...register('username')}
              helperText={errors.email && errors.email.type === 'required' && t('CORE.MESSAGES.FIELD_REQUIRED')}
              error={!!errors.email}
            />
            <Styled.Input
              required
              label="Hasło"
              type="password"
              {...register('password')}
              helperText={errors.password && errors.password.type === 'required' && t('CORE.MESSAGES.FIELD_REQUIRED')}
              error={!!errors.password}
            />
            <Styled.SubmitButton variant="contained" type="submit">
              {t('CORE.MESSAGES.LOGIN')}
            </Styled.SubmitButton>
            <Styled.LinkButton variant="contained" onClick={() => handleClick()}>
              Wykup dostęp
            </Styled.LinkButton>
          </Box>
        </Styled.LoginForm>
      </Styled.LoginWrapper>
    </>
  );
};
