import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Styled } from './Select.styles';
import { SelectProps } from './Select.types';

export const Select = ({ label, value, onChange, options }: SelectProps) => {
  return (
    <>
      <Box>
        <FormControl fullWidth>
          <Styled.SelectBaseLabel id="demo-simple-select-label">{label}</Styled.SelectBaseLabel>
          <Styled.SelectBase
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={onChange}
          >
            {options &&
              options.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
          </Styled.SelectBase>
        </FormControl>
      </Box>
    </>
  );
};
