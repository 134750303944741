import * as yup from 'yup';

import { REQUIRED } from 'utils/validations';
import { TranslationFn } from 'hooks/useLocale/useLocale.types';

export const loginSchema = (t: TranslationFn) =>
  yup.object().shape({
    username: REQUIRED(t),
    password: REQUIRED(t),
  });
