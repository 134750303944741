import { CloseModalProps } from './CloseModal.types';
import { Styled } from './CloseModal.styles';

export const CloseModal = ({ handleClose }: CloseModalProps) => {
  return (
    <Styled.CloseModal>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.714"
        height="15.714"
        viewBox="0 0 15.714 15.714"
        onClick={handleClose}
      >
        <g id="Group_25136" data-name="Group 25136" transform="translate(-333.143 -17.31)">
          <rect
            id="Rectangle_295"
            data-name="Rectangle 295"
            width="20"
            height="2.222"
            transform="translate(334.715 17.31) rotate(45)"
            fill="#fff"
          />
          <rect
            id="Rectangle_4668"
            data-name="Rectangle 4668"
            width="20"
            height="2.222"
            transform="translate(333.144 31.452) rotate(-45)"
            fill="#fff"
          />
        </g>
      </svg>
    </Styled.CloseModal>
  );
};
