import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

import { ModalImageSlider } from '../modalImageSlider/ModalImageSlider';

import { Styled } from './ImageSlider.styles';
import { ImageSliderProps } from './ImageSlider.types';

import 'swiper/swiper-bundle.css';

export const ImageSlider = ({ photoUrls }: ImageSliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = (index: number) => {
    setSliderIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {photoUrls && photoUrls.length ? (
        <Styled.ImageSliderWrapper data-testid="image-slider-wrapper">
          <Swiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="bigImage"
            data-testid="big-image-slider"
          >
            {photoUrls.map((photoUrl, index) => {
              return (
                <SwiperSlide key={index} onClick={() => handleOpen(index)}>
                  <img
                    loading="lazy"
                    src={`${photoUrl}?w=952&h=418&fit=crop&auto=format`}
                    srcSet={`${photoUrl}?w=952&h=418&fit=crop&auto=format&dpr=2 2x`}
                    data-testid={`image-${index}`}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            //@ts-ignore
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={20}
            slidesPerView={2}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="thumbs"
            direction="vertical"
            data-testid="thumbs-slider"
          >
            {photoUrls.map((photoUrl, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={`${photoUrl}`} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Styled.ImageSliderWrapper>
      ) : null}
      <ModalImageSlider open={open} handleClose={handleClose} photoUrls={photoUrls} activeIndex={sliderIndex} />
    </>
  );
};
