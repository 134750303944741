import { Language } from 'context/locale/AppLocale.enum';

import enMessages from './data/en.json';
import plMessages from './data/pl.json';

type KeyAsValue<T> = { [P in keyof T]: P };

const keysToValues = <T extends Record<string, string>>(source: T): KeyAsValue<typeof source> => {
  return (Object.keys(source) as Array<keyof T>).reduce((accumulated, current) => {
    accumulated[current] = current;
    return accumulated;
  }, {} as KeyAsValue<typeof source>);
};

export const Messages = {
  ...keysToValues(enMessages),
  ...keysToValues(plMessages),
};

export const messages: Record<Language, Record<keyof typeof Messages, string>> = {
  en: enMessages,
  pl: plMessages,
};
