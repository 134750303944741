import styled, { css } from 'styled-components';

const InvestmentDetailsWrapper = styled.div<{ margin?: boolean }>(
  ({ theme, margin }) => css`
    max-width: 952px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${margin ? `30px` : '0'};
    ${theme.breakpoints.from('md')} {
      margin-top: ${margin ? `50px` : '0'};
    }
    padding: 0 26px;
    ${theme.breakpoints.from('lg')} {
      padding: 0;
      width: 100%;
    }
  `,
);

InvestmentDetailsWrapper.displayName = 'InvestmentDetails--Wrapper';

const InvestmentDetailsCol = styled.div(
  ({ theme }) => css`
    width: 100%;
    ${theme.breakpoints.from('md')} {
      width: 48%;
    }
  `,
);

InvestmentDetailsCol.displayName = 'InvestmentDetails--Col';

const InvestmentDetailsRow = styled.div<{ borderBottom?: boolean; mobileBorder?: boolean }>(
  ({ theme, borderBottom, mobileBorder }) => css`
    display: flex;
    border-top: 1px solid ${theme.colors.grey};
    padding: 12px 0;
    justify-content: space-between;

    ${theme.breakpoints.to('md')} {
      border-bottom: ${mobileBorder ? `1px solid ${theme.colors.grey}` : 'none'};
    }
    ${theme.breakpoints.from('md')} {
      border-bottom: ${borderBottom ? `1px solid ${theme.colors.grey}` : 'none'};
    }
  `,
);

InvestmentDetailsRow.displayName = 'InvestmentDetails--Row';

const InvestmentDetailsRowCol = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 50%;
  `,
);

InvestmentDetailsRowCol.displayName = 'InvestmentDetailsRow--Col';

const InvestmentDetailsRowColName = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    ${theme.breakpoints.from('xs')} {
      font-size: 14px;
    }
  `,
);

InvestmentDetailsRowColName.displayName = 'InvestmentDetailsRowCol--Name';

const InvestmentDetailsRowColData = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    ${theme.breakpoints.from('xs')} {
      font-size: 14px;
    }
  `,
);

InvestmentDetailsRowColData.displayName = 'InvestmentDetailsRowCol--Data';

const InvestmentDetailsHeading = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-top: 40px;
    ${theme.breakpoints.from('xs')} {
      margin-top: 50px;
      font-size: 26px;
    }
    padding: 0 26px;
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }
  `,
);

InvestmentDetailsHeading.displayName = 'InvestmentDetails--Heading';

const InvestmentDetailsDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.description};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    line-height: 26px;
    margin-top: 20px;
    ${theme.breakpoints.from('xs')} {
      font-size: 16px;
      line-height: 30px;
    }

    span {
      text-decoration: underline;
      margin-left: 5px;
      cursor: pointer;
    }
  `,
);

InvestmentDetailsDescription.displayName = 'InvestmentDetails--Description';

export const Styled = {
  InvestmentDetailsWrapper,
  InvestmentDetailsCol,
  InvestmentDetailsRow,
  InvestmentDetailsRowCol,
  InvestmentDetailsRowColName,
  InvestmentDetailsRowColData,
  InvestmentDetailsHeading,
  InvestmentDetailsDescription,
};
