import * as React from 'react';
import { useCookies } from 'react-cookie';

import { Styled } from './Cookie.styles';

export const Cookie = () => {
  const [cookies, setCookie] = useCookies(['cookie-acceptance']);

  const handleClick = (value: boolean) => {
    setCookie('cookie-acceptance', value, { path: '/' });
  };

  return (
    <>
      {cookies['cookie-acceptance'] !== 'true' && (
        <Styled.StyledCookie>
          <div id="cookieconsent:desc" className="cc-message">
            Ta strona korzysta z ciasteczek.{' '}
            <a
              aria-label="learn more about cookies"
              role="button"
              className="cc-link"
              href="https://propone.pl/policies/privacy-policy"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Dowiedz się więcej
            </a>
          </div>
          <div>
            <button onClick={() => handleClick(true)}>Rozumiem</button>
          </div>
        </Styled.StyledCookie>
      )}
    </>
  );
};
