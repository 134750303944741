/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
      <path d="m5 0l-5 5 5 5 1-1-4-4 4-4" />
    </svg>
  );
};
