import React from 'react';

import { ResetButtonProps } from './ResetButton.types';
import { Styled } from './ResetButton.styles';

export const ResetButton = ({ title, onClick, testId }: ResetButtonProps) => {
  return (
    <Styled.StyledButton onClick={onClick} data-testid={testId}>
      {title}
    </Styled.StyledButton>
  );
};
