import React from 'react';

import { Styled } from './Search.styles';
import { SearchProps } from './Search.types';

export const Search = ({ label, value, onChange }: SearchProps) => {
  return (
    <>
      <Styled.Input label={label} value={value} onChange={onChange} />
    </>
  );
};
