import styled, { css } from 'styled-components';

const ImageSliderWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 12px;
    margin-top: 32px;

    .bigImageModal {
      width: 90%;
      margin: 0;
      ${theme.breakpoints.from('md')} {
        width: 736px;
      }
      img {
        width: 100%;
        height: 320px;
        object-fit: cover;
        object-position: bottom;
        ${theme.breakpoints.from('xs')} {
          height: 420px;
        }
        ${theme.breakpoints.from('md')} {
          height: 520px;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 24px;
        height: 24px;
        background: ${theme.colors.white};
        border-radius: 50%;
        box-shadow: 0px 5px 10px #00000029;
        ${theme.breakpoints.from('xs')} {
          width: 48px;
          height: 48px;
        }

        &:after {
          font-size: 13px;
          color: ${theme.colors.arrow};
          ${theme.breakpoints.from('xs')} {
            font-size: 22px;
          }
        }
      }
    }
    .thumbsModal {
      display: none;
      ${theme.breakpoints.from('md')} {
        display: initial;
      }
      .swiper-wrapper {
        display: flex;
        flex-direction: row;
      }
      .swiper-slide {
        height: 88px !important;
        width: 88px !important;
        border: 2px solid transparent;

        img {
          height: 88px;
          width: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }

      .swiper-slide-thumb-active {
        border: 2px solid #ffffff;
      }
    }
  `,
);

ImageSliderWrapper.displayName = 'ImageSlider--Wrapper';

const ModalWrapper = styled.div(
  () => css`
    svg {
      position: absolute;
    }
  `,
);

ModalWrapper.displayName = 'ModalWrapper';

export const Styled = {
  ImageSliderWrapper,
  ModalWrapper,
};
