import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

export const useFilters = () => {
  const [location, setLocation] = useState('');
  const [startPrice, setStartPrice] = useState('');
  const [endPrice, setEndPrice] = useState('');
  const [startSquare, setStartSquare] = useState('');
  const [endSquare, setEndSquare] = useState('');
  const [termId, setTermId] = useState('');

  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  const handleStartPriceChange = (event: SelectChangeEvent) => {
    setStartPrice(event.target.value as string);
  };

  const handleEndPriceChange = (event: SelectChangeEvent) => {
    setEndPrice(event.target.value as string);
  };

  const handleStartSquareChange = (event: SelectChangeEvent) => {
    setStartSquare(event.target.value as string);
  };

  const handleEndSquareChange = (event: SelectChangeEvent) => {
    setEndSquare(event.target.value as string);
  };

  const handleDateChange = (event: SelectChangeEvent) => {
    setTermId(event.target.value as string);
  };

  const handleReset = () => {
    setLocation('');
    setStartPrice('');
    setEndPrice('');
    setStartSquare('');
    setEndSquare('');
    setTermId('');
  };

  return {
    location,
    startPrice,
    endPrice,
    startSquare,
    endSquare,
    termId,
    handleLocationChange,
    handleStartPriceChange,
    handleEndPriceChange,
    handleStartSquareChange,
    handleEndSquareChange,
    handleDateChange,
    handleReset,
  };
};
