import styled, { css } from 'styled-components';

const ImageSliderWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 32px;
    ${theme.breakpoints.from('md')} {
      padding: 0 26px;
    }
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }

    .bigImage {
      width: 100%;
      margin: 0;
      ${theme.breakpoints.from('md')} {
        width: 75%;
      }
      ${theme.breakpoints.from('lg')} {
        width: 952px;
      }

      .swiper-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #d5d5dc;
      }
      img {
        max-width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: bottom;
        ${theme.breakpoints.from('xs')} {
          height: 418px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 24px;
        height: 24px;
        background: ${theme.colors.white};
        border-radius: 50%;
        box-shadow: 0px 5px 10px #00000029;
        ${theme.breakpoints.from('xs')} {
          width: 48px;
          height: 48px;
        }

        &:after {
          font-size: 13px;
          color: ${theme.colors.arrow};
          ${theme.breakpoints.from('xs')} {
            font-size: 22px;
          }
        }
      }
    }
    .thumbs {
      width: 23%;
      margin: 0;
      height: 418px;
      display: none;
      ${theme.breakpoints.from('md')} {
        display: block;
      }
      ${theme.breakpoints.from('lg')} {
        width: calc(100% - 972px);
      }
      .swiper-wrapper {
        display: flex;
        flex-direction: column;
      }
      .swiper-slide {
        height: 199px !important;
        width: 100% !important;
        margin: 0 !important;
        &:not(:first-child) {
          margin-top: 20px !important;
        }

        img {
          height: 199px;
          width: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }
    }
  `,
);

ImageSliderWrapper.displayName = 'ImageSlider--Wrapper';

export const Styled = {
  ImageSliderWrapper,
};
