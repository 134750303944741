/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetTokens, setTokens } from '../authActionCreators/authActionCreators';
import { AuthContext } from '../authContext/AuthContext';
import { AuthContextValue } from '../authContext/AuthContext.types';
import { authReducer } from '../authReducer/authReducer';
import { authStorage } from '../authStorage/AuthStorage';
import { useToast } from 'hooks/useToast/useToast';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    accessToken: authStorage.accessToken,
    refreshToken: authStorage.refreshToken,
    expires: authStorage.expires,
  });

  const BASE_AUTH_URL = process.env.REACT_APP_AUTH_API_URL;

  const navigate = useNavigate();
  const { showSnackBar } = useToast();

  const loginMutation = (username: string, password: string) => {
    return fetch(BASE_AUTH_URL + 'connect/token', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: new URLSearchParams({
        username: username,
        password: password,
        client_id: 'map_spa',
        grant_type: 'password',
        scope: 'openid email phone profile offline_access roles propone_api',
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        dispatch(
          setTokens({
            accessToken: responseData.access_token,
            refreshToken: responseData.refresh_token,
            expires: responseData.expires_in,
          }),
        );
        navigate('/map');
      })
      .catch((error) => {
        showSnackBar('Wystąpił błąd podczas logowania', 'error');
        console.error(error);
        dispatch(resetTokens());
      });
  };

  const logout = useCallback(() => {
    dispatch(resetTokens());
  }, []);

  useEffect(() => {
    authStorage.accessToken = state.accessToken;
    authStorage.expires = state.expires;
    authStorage.refreshToken = state.refreshToken;
  }, [state]);

  const value: AuthContextValue = useMemo(
    () => ({
      ...state,
      logout,
      loginMutation,
    }),
    [state, logout, loginMutation],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
