import * as React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

export const useFilters = () => {
  const [offerId, setOfferId] = React.useState('');

  const handleOfferIdChange = (id: string) => {
    setOfferId(id);
  };

  const [startPrice, setStartPrice] = React.useState('');

  const handleStartPriceChange = (event: SelectChangeEvent) => {
    setStartPrice(event.target.value as string);
  };

  const [endPrice, setEndPrice] = React.useState('');

  const handleEndPriceChange = (event: SelectChangeEvent) => {
    setEndPrice(event.target.value as string);
  };

  const [startSquare, setStartSquare] = React.useState('');

  const handleStartSquareChange = (event: SelectChangeEvent) => {
    setStartSquare(event.target.value as string);
  };

  const [endSquare, setEndSquare] = React.useState('');

  const handleEndSquareChange = (event: SelectChangeEvent) => {
    setEndSquare(event.target.value as string);
  };

  const [startRoom, setStartRoom] = React.useState('');

  const handleStartRoomChange = (event: SelectChangeEvent) => {
    setStartRoom(event.target.value as string);
  };

  const [endRoom, setEndRoom] = React.useState('');

  const handleEndRoomChange = (event: SelectChangeEvent) => {
    setEndRoom(event.target.value as string);
  };

  const [startFloor, setStartFloor] = React.useState('');

  const handleStartFloorChange = (event: SelectChangeEvent) => {
    setStartFloor(event.target.value as string);
  };

  const [endFloor, setEndFloor] = React.useState('');

  const handleEndFloorChange = (event: SelectChangeEvent) => {
    setEndFloor(event.target.value as string);
  };

  const handleReset = () => {
    setEndFloor('');
    setStartFloor('');
    setEndRoom('');
    setStartRoom('');
    setEndSquare('');
    setStartSquare('');
    setEndPrice('');
    setStartPrice('');
  };

  return {
    offerId,
    handleOfferIdChange,
    startPrice,
    handleStartPriceChange,
    endPrice,
    handleEndPriceChange,
    startSquare,
    handleStartSquareChange,
    endSquare,
    handleEndSquareChange,
    startRoom,
    handleStartRoomChange,
    endRoom,
    handleEndRoomChange,
    startFloor,
    handleStartFloorChange,
    endFloor,
    handleEndFloorChange,
    handleReset,
    setStartPrice,
  };
};
