import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { GlobalStyles } from 'assets/themes/globalStyles';
import { createTheme } from 'assets/themes/createTheme';
import { defaultTheme } from 'assets/themes/defaultTheme';

import { ThemeProviderProps } from './ThemeProvider.types';

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <StyledThemeProvider theme={createTheme(defaultTheme, { isEnglish: false })}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  );
};
