/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const DownloadIcon = ({ scale }: any) => {
  const styles = {
    transform: `scale(${scale})`,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g id="Group_20026" data-name="Group 20026" transform="translate(-65 -3025)">
        <path
          id="icons8-floor-plan"
          d="M4.556,3A1.567,1.567,0,0,0,3,4.556V15.444A1.567,1.567,0,0,0,4.556,17H15.444A1.567,1.567,0,0,0,17,15.444V4.556A1.567,1.567,0,0,0,15.444,3H12.333V4.556h3.111V9.222H13.889v1.556h1.556v4.667H10V10.778h1.556V9.222H6.889v1.556H8.444v4.667H4.556V4.556H8.122l2.326,2.326,1.1-1.1L8.766,3Z"
          transform="translate(62 3022)"
          fill="#011626"
        />
      </g>
    </svg>
  );
};
