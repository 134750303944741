import styled, { css } from 'styled-components';

const FiltersWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 10px;
    min-height: 90px;
    justify-content: space-between;
    padding: 35px 26px;
    flex-direction: column;
    ${theme.breakpoints.from('md')} {
      flex-direction: row;
      align-items: center;
      padding: 10px 26px;
    }
    ${theme.breakpoints.from('lg')} {
      padding: 10px 0px;
      width: 100%;
      justify-content: center;
    }
  `,
);

FiltersWrapper.displayName = 'FiltersWrapper--Wrapper';

const FiltersWrapperMenuCol = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    span {
      margin: 0 5px;
    }
    .MuiFormControl-root {
      width: 49%;
      ${theme.breakpoints.from('md')} {
        width: 130px;
      }
      ${theme.breakpoints.from('lg')} {
        width: 142px;
      }
    }
    .MuiButtonBase-root {
      width: 48%;
      ${theme.breakpoints.from('md')} {
        width: 142px;
      }
    }
  `,
);

FiltersWrapperMenuCol.displayName = 'FiltersWrapperMenu--Col';

export const Styled = {
  FiltersWrapper,
  FiltersWrapperMenuCol,
};
