import { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { DetailsActionsResponse, GetDetailsArg, GetOffersArg, OffersResponse } from './detailsActions.types';

export const detailsQueries = {
  getLocationDetails:
    (client: AxiosInstance) =>
    async ({ id }: GetDetailsArg) => {
      return (await client.get<DetailsActionsResponse>(`/investment/${id}`)).data;
    },
  getOffers:
    (client: AxiosInstance) =>
    async ({
      id,
      startPrice,
      endPrice,
      startSquare,
      endSquare,
      startRoom,
      endRoom,
      startFloor,
      endFloor,
    }: GetOffersArg) => {
      const queryParams = stringify(
        { startPrice, endPrice, startSquare, endSquare, startRoom, endRoom, startFloor, endFloor },
        { addQueryPrefix: true },
      );
      return await client.get<OffersResponse>(`/investment/${id}/flats/${queryParams}`);
    },
};
