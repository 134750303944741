import { Map } from './components/map/Map';
import { GoogleMapProps } from './GoogleMap.types';

export const GoogleMap = ({ markers, isFetching }: GoogleMapProps) => {
  return (
    <>
      <Map markers={markers} isFetching={isFetching} />
    </>
  );
};
