import React, { useState } from 'react';
import { AlertColor } from '@mui/material';

import { Snackbar } from 'components/snackbar/Snackbar';
import { ToastContext } from '../toastContext/ToastContext';

import { ToastContextControllerProps } from './ToastContextController.types';

export const ToastContextController = ({ children }: ToastContextControllerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [typeColor, setTypeColor] = useState<AlertColor>('success');

  const showSnackBar = (text: string, color: AlertColor) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ToastContext.Provider value={{ showSnackBar }}>
      <Snackbar message={message} severity={typeColor} open={open} onClose={handleClose} />
      {children}
    </ToastContext.Provider>
  );
};
