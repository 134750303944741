import { Colors } from './colors.types';

export const customColors: Colors = {
  blackLowOpacity: 'rgba(0, 0, 0, 0.2)',
  purple: '#3A003B',
  dark: '#0F0F0F',
  lightGrey: '#92929D',
  white: '#FFFFFF',
  inputText: '#011626',
  orange: '#ff5100',
  arrow: '#0E2C42',
  grey: '#D5D5DC',
  description: '#4C5B67',
  tableHeading: '#171725',
  resetButton: '#F8F8FB',
  black: '#000000',
};
