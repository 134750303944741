import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';

const Input = styled(TextField)(
  ({ theme }) => css`
    .MuiInputBase-root {
      height: 40px;
      color: ${theme.colors.inputText};
      font-size: 14px;
      font-weight: 600;
      border-radius: 7px;
      width: 100%;

      ${theme.breakpoints.from('sm')} {
        height: 48px;
      }
    }
    .MuiInputBase-input {
      height: 40px;
      ${theme.breakpoints.from('sm')} {
        height: 48px;
      }
    }
    &.MuiFormControl-root {
      height: 40px;
      ${theme.breakpoints.from('sm')} {
        height: 48px;
      }
      ${theme.breakpoints.from('md')} {
        width: 290px;
      }
      ${theme.breakpoints.from('lg')} {
        width: 304px;
      }
    }
    .MuiFormLabel-root {
      font-size: 13px;
      transform: translate(14px, 12px) scale(1);
      ${theme.breakpoints.from('sm')} {
        transform: translate(14px, 16px) scale(1);
      }
      &[data-shrink='true'] {
        transform: translate(14px, -8px) scale(0.75);
      }
    }
  `,
);

Input.displayName = 'Input--Search';

export const Styled = {
  Input,
};
