import React, { useState } from 'react';

import { Styled } from './InvestmentDetails.styles';
import { InvestmentDetailsProps } from './InvestmentDetails.types';

export const InvestmentDetails = ({ details }: InvestmentDetailsProps) => {
  const [showMore, setShowMore] = useState(false);
  const description = details?.investmentDescription;

  const checkIfExist = (value: any) => {
    return value && value !== null && value !== 0 ? true : false;
  };

  return (
    <>
      <Styled.InvestmentDetailsWrapper margin>
        <Styled.InvestmentDetailsCol>
          {checkIfExist(details?.id) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Id inwestycji</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.id}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.investmentName) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Nazwa inwestycji</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.investmentName}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.developerName) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Deweloper</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.developerName}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.priceMin) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Cena</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>
                  {details?.priceMin.toLocaleString()} - {details?.priceMax.toLocaleString()} {details?.currency}
                </Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.pricePerMeterMin) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Cena m²</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>
                  {details?.pricePerMeterMin.toLocaleString()} - {details?.pricePerMeterMax.toLocaleString()}{' '}
                  {details?.currency}
                </Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.currency) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Waluta</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.currency}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.areaMin) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Powierzchnia</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>
                  {details?.areaMin} - {details?.areaMax} m²
                </Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}
        </Styled.InvestmentDetailsCol>
        <Styled.InvestmentDetailsCol>
          {checkIfExist(details?.floorCount) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Liczba pięter</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.floorCount}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.roomHeight) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Wysokość pomieszczeń</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.roomHeight}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.parkingPlaces) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Miejsca postojowe</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.parkingPlaces}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.storageRoomsCount) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>komórki lokatorskie</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.storageRoomsCount}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.propertyType) && (
            <Styled.InvestmentDetailsRow>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Typ lokalu</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>{details?.propertyType}</Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}

          {checkIfExist(details?.safeties) && (
            <Styled.InvestmentDetailsRow borderBottom mobileBorder>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColName>Udogodnienia</Styled.InvestmentDetailsRowColName>
              </Styled.InvestmentDetailsRowCol>
              <Styled.InvestmentDetailsRowCol>
                <Styled.InvestmentDetailsRowColData>
                  {details?.safeties &&
                    details?.safeties.map((item, index) => {
                      return (
                        <span key={index}>
                          {index !== 0 ? ', ' : ''}
                          {item}
                        </span>
                      );
                    })}
                  {!details?.safeties.length && '-'}
                </Styled.InvestmentDetailsRowColData>
              </Styled.InvestmentDetailsRowCol>
            </Styled.InvestmentDetailsRow>
          )}
        </Styled.InvestmentDetailsCol>
      </Styled.InvestmentDetailsWrapper>
      <Styled.InvestmentDetailsHeading>{description && <> Opis inwestycji</>}</Styled.InvestmentDetailsHeading>
      <Styled.InvestmentDetailsWrapper>
        {description && (
          <Styled.InvestmentDetailsDescription>
            {showMore ? description : `${String(description).substring(0, 400)}`}
            <span onClick={() => setShowMore(!showMore)}> {showMore ? 'Zwiń' : 'Rozwiń'}</span>
          </Styled.InvestmentDetailsDescription>
        )}
      </Styled.InvestmentDetailsWrapper>
      <Styled.InvestmentDetailsHeading>Znajdź mieszkanie</Styled.InvestmentDetailsHeading>
    </>
  );
};
