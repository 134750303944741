import { createBreakpoints } from 'assets/themes/breakpoints';
import { Theme } from 'assets/themes/theme.types';

export const createTheme = (theme: Theme, { isEnglish }: { isEnglish: boolean }) => {
  return {
    ...theme,
    breakpoints: createBreakpoints(theme.breakpoints),
    isEnglish,
  };
};

export type StyledTheme = ReturnType<typeof createTheme>;
