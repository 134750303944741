import React from 'react';

export const CloseIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
        <g id="Group_25302" data-name="Group 25302" transform="translate(-333.143 -17.31)">
          <rect
            id="Rectangle_295"
            data-name="Rectangle 295"
            width="12.728"
            height="1.414"
            transform="translate(334.143 17.31) rotate(45)"
            fill="#92929d"
          />
          <rect
            id="Rectangle_4668"
            data-name="Rectangle 4668"
            width="12.728"
            height="1.414"
            transform="translate(333.143 26.31) rotate(-45)"
            fill="#92929d"
          />
        </g>
      </svg>
    </>
  );
};
