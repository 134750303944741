import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)<{ reset?: boolean }>(
  ({ theme, reset }) => css`
    width: 100%;
    &.MuiButtonBase-root {
      background-color: ${reset ? theme.colors.resetButton : theme.colors.purple};
      color: ${reset ? theme.colors.inputText : theme.colors.white};
      height: 42px;
      font-weight: 600;
      font-size: 12px;
      text-transform: none;
      border-radius: 7px;
      ${theme.breakpoints.from('sm')} {
        height: 48px;
        font-size: 14px;
        width: 142px;
      }

      &:hover {
         background-color: ${reset ? theme.colors.resetButton : theme.colors.purple};
         opacity: 0.8;
      }
  `,
);

StyledButton.displayName = 'StyledButton--Button';

export const Styled = {
  StyledButton,
};
