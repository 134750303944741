import { Breakpoints, MediaQueries } from './breakpoints.types';
import { MediaRange } from './theme.enum';

export const breakpoints: Breakpoints = {
  xs: 580,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
};

export const MEDIA = '@media';

export const createBreakpoints = (breakpoints: Breakpoints) => {
  const mediaQueries: MediaQueries = (range) => (key) =>
    `${MEDIA} (${range === MediaRange.FROM ? 'min-width' : 'max-width'}: ${breakpoints[key]}px)`;

  const from = mediaQueries(MediaRange.FROM);
  const to = mediaQueries(MediaRange.TO);

  return {
    from,
    to,
  };
};
