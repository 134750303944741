import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { Popover } from 'react-tiny-popover';

const CustomMarker = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    position: relative;
    background: transparent;

    svg {
      width: 60px;
    }

    span {
      font-size: 9px;
      color: ${theme.colors.white};
      font-weight: 600;
      display: block;
      text-align: center;
      line-height: 8px;
    }
  `,
);

CustomMarker.displayName = 'MapWrapper--CustomMarker';

const ContentWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.white};
    opacity: 1;
    width: 578px;
    min-height: 230px;
    display: flex;
    box-shadow: 5px 5px 15px #00000065;
    position: relative;
  `,
);

ContentWrapper.displayName = 'ContentWrapper';

const CloseIconWrapper = styled.div(
  () => css`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    display: flex;

    svg {
      width: 10px;
      height: 10px;

      &: hover {
        cursor: pointer;
      }
    }
  `,
);

CloseIconWrapper.displayName = 'CloseIcon--Wrapper';

const ContentWrapperImage = styled.div(
  () => css`
    width: 326px;
    min-height: 230px;
    display: flex;
    max-height: 230px;
    background: #d5d5dc;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  `,
);

ContentWrapperImage.displayName = 'ContentWrapper--Image';

const ContentWrapperDescription = styled.div(
  () => css`
    width: calc(100% - 326px);
    max-height: 230px;
  `,
);

ContentWrapperDescription.displayName = 'ContentWrapper--Description';

const ContentWrapperDescriptionWrapper = styled.div(
  () => css`
    padding: 20px;
  `,
);

ContentWrapperDescriptionWrapper.displayName = 'ContentWrapperDescription--Wrapper';

const ContentWrapperDescriptionAddress = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 10px;
    margin: 0;
    line-height: 18px;
  `,
);

ContentWrapperDescriptionAddress.displayName = 'ContentWrapperDescription--Address';

const ContentWrapperDescriptionDeveloper = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 10px;
    margin: 0;
    line-height: 18px;
  `,
);

ContentWrapperDescriptionDeveloper.displayName = 'ContentWrapperDescription--Developer';

const ContentWrapperDescriptionTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 14px;
    margin: 10px 0 0 0;
    line-height: 18px;
    font-weight: 500;
    ${theme.breakpoints.to('xs')} {
      margin: 4px 0 0 0;
    }
  `,
);

ContentWrapperDescriptionTitle.displayName = 'ContentWrapperDescription--Title';

const ContentWrapperDescriptionPriceRange = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 14px;
    margin: 5px 0 0 0;
    line-height: 18px;
    font-weight: bold;
    ${theme.breakpoints.to('xs')} {
      margin: 10px 0 0 0;
    }
  `,
);

ContentWrapperDescriptionPriceRange.displayName = 'ContentWrapperDescription--PriceRange';

const ContentWrapperDescriptionUnitPrice = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 10px;
    margin: 5px 0 0 0;
    line-height: 16px;
    font-weight: 400;
  `,
);

ContentWrapperDescriptionUnitPrice.displayName = 'ContentWrapperDescription--UnitPrice';

const ContentWrapperDescriptionInfo = styled.div(
  () => css`
    display: flex;
    margin-top: 10px;
  `,
);

ContentWrapperDescriptionInfo.displayName = 'ContentWrapperDescription--Info';

const ContentWrapperDescriptionInfoSingle = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
      margin: 0;
      color: ${theme.colors.inputText};
      font-size: 10px;
      font-weight: 500;
      padding-left: 8px;
      padding-right: 20px;
      line-height: 10px;
    }
  `,
);

ContentWrapperDescriptionInfoSingle.displayName = 'ContentWrapperDescription--InfoSingle';

const ContentWrapperDescriptionButton = styled(Button)(
  ({ theme }) => css`
    height: 30px;

    &.MuiButtonBase-root {
      color: ${theme.colors.inputText};
      border-color: ${theme.colors.inputText};
      width: 100%;
      font-size: 10px;
    }
  `,
);

ContentWrapperDescriptionButton.displayName = 'ContentWrapperDescription--Button';

const CustomMarkerPin = styled.div(
  () => css`
    position: absolute;
    top: 10px;
    text-align: center;
    width: 60px;
    color: white;
  `,
);

CustomMarkerPin.displayName = 'CustomMarker--Pin';

const CustomMarkerPopover = styled(Popover)(
  () => css`
    z-index: 444444;
  `,
);

CustomMarkerPopover.displayName = 'CustomMarker--Popover';

export const Styled = {
  CustomMarker,
  ContentWrapper,
  ContentWrapperImage,
  ContentWrapperDescription,
  ContentWrapperDescriptionWrapper,
  ContentWrapperDescriptionAddress,
  ContentWrapperDescriptionDeveloper,
  ContentWrapperDescriptionTitle,
  ContentWrapperDescriptionPriceRange,
  ContentWrapperDescriptionUnitPrice,
  ContentWrapperDescriptionInfo,
  ContentWrapperDescriptionInfoSingle,
  ContentWrapperDescriptionButton,
  CustomMarkerPin,
  CloseIconWrapper,
  CustomMarkerPopover,
};
