import styled, { css } from 'styled-components';

const InfoButtonWrapper = styled.div(
  () => css`
    margin-left: 6px;
  `,
);

InfoButtonWrapper.displayName = 'InfoButton--Wrapper';

export const Styled = {
  InfoButtonWrapper,
};
