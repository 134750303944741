/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nContext } from 'context/i18n/I18nContext';

import { TranslationFn } from './useLocale.types';

export const useLocale = () => {
  const intl = useIntl();
  const i18n = useContext(I18nContext);

  if (i18n === undefined) {
    throw new Error('I18nContext is unavailable, make sure you are using I18nProvider');
  }

  const t: TranslationFn = (id, value) => {
    return intl.formatMessage({ id }, value);
  };

  const isEnglish = useMemo(() => i18n.locale === 'en', [i18n.locale]);

  const locale = useMemo(
    () => ({
      ...intl,
      ...i18n,
      t,
      isEnglish,
    }),
    [intl, i18n],
  );

  return locale;
};
