import styled, { css } from 'styled-components';

const CloseModal = styled.div(
  () => css`
    position: absolute;
    right: 30px;
    top: 30px;

    &:hover {
      cursor: pointer;
    }
  `,
);

CloseModal.displayName = 'CloseModal';

export const Styled = {
  CloseModal,
};
