import { Language } from 'context/locale/AppLocale.enum';

export const SERVER_RESPONSE_TIME_MS = 500;

export const DATA_ERROR_OBJECT = { error: true, message: { ar: 'Server error', en: 'Server error' } };

export type AuthApiConfig = {
  userName: string;
  language: Language;
};

export const authApiConfig: AuthApiConfig = {
  language: 'pl',
  userName: 'Paweł Kowalski',
};
