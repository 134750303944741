import { useMemo } from 'react';

import { createApiInstance } from 'api/utils/createApiInstance';
import { requestSuccessInterceptor } from 'context/apiClient/apiClientContextController/interceptors/requestInterceptors';
import {
  responseFailureInterceptor,
  responseSuccessInterceptor,
} from 'context/apiClient/apiClientContextController/interceptors/responseInterceptors';
export const BASE_URL = process.env.REACT_APP_API_URL;

export const useApiClients = () => {
  return useMemo(() => {
    const propOne = createApiInstance(BASE_URL);

    propOne.interceptors.request.use(requestSuccessInterceptor);
    propOne.interceptors.response.use(responseSuccessInterceptor, responseFailureInterceptor);

    return { propOne };
  }, []);
};
