import { AxiosRequestConfig } from 'axios';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const requestAuthInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    };
  }

  return config;
};
