import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const LoginWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    max-width: 900px;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    padding: 300px 24px 66px 24px;

    ${theme.breakpoints.from('sm')} {
      flex-direction: row;
      justify-content: space-between;
      min-height: 100vh;
      max-height: initial;
      padding: 0 24px;
    }
    ${theme.breakpoints.from('xl')} {
      padding: 0;
    }
  `,
);

LoginWrapper.displayName = 'LoginWrapper--Wrapper';

const LoginTitle = styled.h1(
  ({ theme }) => css`
    font-size: 20px;
    color: ${theme.colors.dark};
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 30px 0;
    ${theme.breakpoints.from('xs')} {
      font-size: 28px;
      line-height: 34px;
    }
    ${theme.breakpoints.from('sm')} {
      font-size: 38px;
      line-height: 48px;
      max-width: 412px;
      width: 50%;
      margin: 0;
    }
    ${theme.breakpoints.from('md')} {
      font-size: 48px;
      line-height: 60px;
    }
  `,
);

LoginTitle.displayName = 'LoginTitle--Title';

const LoginForm = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.from('sm')} {
      width: 304px;
    }
  `,
);

LoginForm.displayName = 'LoginForm--Form';

const Input = styled(TextField)(
  ({ theme }) => css`
    width: 100%;

    &.MuiTextField-root {
      margin-bottom: 20px;
    }

    .MuiInputBase-root {
      height: 40px;

      ${theme.breakpoints.from('sm')} {
        height: 49px;
      }
    }

    input {
      color: ${theme.colors.inputText};
      font-weight: 500;
      font-size: 12px;
      ${theme.breakpoints.from('sm')} {
        font-size: 14px;
      }
    }
  `,
);

Input.displayName = 'Input';

const SubmitButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;
    &.MuiButtonBase-root {
      background-color: ${theme.colors.purple};
      height: 40px;
      font-weight: 500;
      font-size: 12px;
      text-transform: none;
      ${theme.breakpoints.from('sm')} {
        height: 49px;
        font-size: 14px;
      }
  `,
);

SubmitButton.displayName = 'SubmitButton--Form';

const LinkButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;
    margin-top: 20px!important;
    &.MuiButtonBase-root {
      background-color: ${theme.colors.purple};
      height: 40px;
      font-weight: 500;
      font-size: 12px;
      text-transform: none;

      opacity: 0.7;
      ${theme.breakpoints.from('sm')} {
        height: 49px;
        font-size: 14px;
      }
  `,
);

LinkButton.displayName = 'LinkButton--Form';

export const Styled = {
  LoginWrapper,
  LoginTitle,
  LoginForm,
  Input,
  SubmitButton,
  LinkButton,
};
