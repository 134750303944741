import React, { useEffect, useState } from 'react';
import { StringParam } from 'use-query-params';

import { useMarkers } from 'hooks/useMarkers/useMarkers';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useToast } from 'hooks/useToast/useToast';

import { GoogleMap } from './GoogleMap';
import { FiltersWrapper } from './components/filtersWrapper/FiltersWrapper';
import { useTerms } from './hooks/getTerms';
import { useFilters } from './hooks/useFilters';

export const mapParams = {
  location: StringParam,
  startPrice: StringParam,
  endPrice: StringParam,
  startSquare: StringParam,
  endSquare: StringParam,
  termId: StringParam,
};

export const GoogleMapContainer = () => {
  const [mapData, setMapData] = useState([]);
  const { showSnackBar } = useToast();
  const {
    location,
    startPrice,
    endPrice,
    startSquare,
    endSquare,
    termId,
    handleLocationChange,
    handleStartPriceChange,
    handleEndPriceChange,
    handleStartSquareChange,
    handleEndSquareChange,
    handleDateChange,
  } = useFilters();

  const { isFetching, refetch } = useMarkers(
    {
      location,
      startPrice,
      endPrice,
      startSquare,
      endSquare,
      termId,
    },
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (res: any) => {
        setMapData(res);
      },
      onError: () => {
        showSnackBar('Wystąpił błąd podczas pobierania danych', 'error');
      },
    },
  );
  const { data: termsData } = useTerms();
  const { t } = useLocale();

  const handleSearchButtonClick = () => {
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  const data = {
    location: {
      label: t('CORE.FILTERS.ENTER_LOCATION'),
      value: location,
      onChange: handleLocationChange,
    },
    startPrice: {
      label: t('CORE.FILTERS.START_PRICE'),
      value: startPrice,
      onChange: handleStartPriceChange,
    },
    endPrice: {
      label: t('CORE.FILTERS.END_PRICE'),
      value: endPrice,
      onChange: handleEndPriceChange,
    },
    startSquare: {
      label: t('CORE.FILTERS.FROM'),
      value: startSquare,
      onChange: handleStartSquareChange,
    },
    endSquare: {
      label: t('CORE.FILTERS.DO'),
      value: endSquare,
      onChange: handleEndSquareChange,
    },
    termId: {
      label: t('CORE.FILTERS.DUE_DATE'),
      value: termId,
      onChange: handleDateChange,
      options: termsData,
    },
  };

  return (
    <>
      <FiltersWrapper
        data={data}
        buttonTitle={t('CORE.FILTERS.FIND')}
        onButtonClick={handleSearchButtonClick}
        termsData={termsData}
      />
      <GoogleMap markers={mapData} isFetching={isFetching} />
    </>
  );
};
