import React from 'react';
import { IntlProvider } from 'react-intl';

import { I18nContext } from 'context/i18n/I18nContext';
import { Language } from 'context/locale/AppLocale.enum';
import { messages } from 'i18n/messages';

import { I18nProviderProps } from './I18nProvider.types';

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const defaultLocale: Language = 'pl';

  const memoizedContextValue = React.useMemo(() => {
    return {
      defaultLocale,
      locale: defaultLocale,
      // setLocale,
      // isChangingLocale,
    };
  }, [defaultLocale]);

  return (
    <IntlProvider locale={memoizedContextValue.defaultLocale} messages={messages[defaultLocale]}>
      <I18nContext.Provider value={memoizedContextValue}>{children}</I18nContext.Provider>
    </IntlProvider>
  );
};
