import React from 'react';

import { Styled } from './BigSearch.styles';
import { BigSearchProps } from './BigSearch.types';

export const BigSearch = ({ label, value, onChange }: BigSearchProps) => {
  return (
    <>
      <Styled.Input label={label} value={value} onChange={onChange} />
    </>
  );
};
