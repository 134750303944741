import styled, { css } from 'styled-components';

const TopDescriptionWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 26px;
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }
  `,
);

TopDescriptionWrapper.displayName = 'TopDescription--Wrapper';

const TopDescriptionDeveloper = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    ${theme.breakpoints.from('sm')} {
      width: 50%;
      font-size: 16px;
    }

    span {
      text-decoration: underline;
      color: ${theme.colors.inputText};
    }
  `,
);

TopDescriptionDeveloper.displayName = 'TopDescription--Developer';

const InvestmentEnd = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 16px;
    font-weight: 400;
    width: 50%;
    text-align: end;
    margin: 0;
    display: none;
    ${theme.breakpoints.from('sm')} {
      display: initial;
    }
  `,
);

InvestmentEnd.displayName = 'Investment--End';

const InvestmentBack = styled.p(
  ({ theme }) => css`
    display: flex;
    margin-top: 32px;
    align-items: center;
    color: ${theme.colors.inputText};
    font-size: 14px;
    font-weight: 400;
    padding: 0 26px;
    ${theme.breakpoints.from('lg')} {
      padding: 0;
    }

    svg {
      width: 7px;
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  `,
);

InvestmentBack.displayName = 'Investment--Back';

export const Styled = {
  TopDescriptionWrapper,
  TopDescriptionDeveloper,
  InvestmentEnd,
  InvestmentBack,
};
