import { createGlobalStyle, css } from 'styled-components';

export const appClassNamePrefix = 'prop-one';

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    html,
    body {
      font-family: 'Poppins', sans-serif;
      margin: 0;
    }

    header.MuiAppBar-root {
      background-color: ${theme.colors.white};
      height: 70px;
    }
    .MuiModal-root .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 1) !important;
    }
    .MuiPopover-root .MuiBackdrop-root {
      background-color: initial !important;
    }

    .MuiBox-root .MuiInputLabel-animated {
      padding: 0 10px 0 5px;
      background: white;
    }
    .react-tiny-popover-container {
      z-index: 44;
      ${theme.breakpoints.to('xs')} {
        position: fixed !important;
        transform: none !important;
        bottom: 0 !important;
        top: auto !important;
        right: 0;
      }

      .popover-arrow-container {
        ${theme.breakpoints.to('xs')} {
          padding: 0 !important;
        }

        #marker-wrapper {
          ${theme.breakpoints.to('xs')} {
            width: 100% !important;
            flex-direction: column !important;
          }
        }
        #image-wrapper {
          ${theme.breakpoints.to('xs')} {
            width: 100% !important;
            height: 150px !important;
          }
        }
        #content-wrapper {
          ${theme.breakpoints.to('xs')} {
            width: 100% !important;
          }
        }
      }
    }
  `,
);
