/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';

import { Marker } from '../marker/Marker';
import { GoogleMapProps } from '../../GoogleMap.types';
import { Spy } from '../Spy';
import { SkeletonWrapper } from '../skeletonWrapper/SkeletonWrapper';

import { Styled } from './Map.styles';
import { MAP } from './Map.const';

export const Map = ({ markers, isFetching }: GoogleMapProps) => {
  const mapRef = useRef();

  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(8);

  const points =
    !isFetching && markers && markers.length
      ? markers.map((marker) => ({
          type: 'Feature',
          properties: {
            cluster: false,
            markerId: marker?.id,
            category: 'wells',
            priceMax: marker?.priceMax,
            priceMin: marker?.priceMin,
          },
          geometry: {
            type: 'Point',
            coordinates: [marker?.longitude, marker?.latitude],
          },
        }))
      : [];

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds,
    zoom,
    options: {
      radius: 120,
      maxZoom: 20,
      nodeSize: 0,
      generateId: false,
      minPoints: 2,
    },
  });

  return (
    <>
      <Styled.MapWrapper>
        <GoogleMapReact
          defaultZoom={MAP.defaultZoom}
          defaultCenter={MAP.defaultCenter}
          bootstrapURLKeys={{ key: 'AIzaSyCo6VA6plElshvFwaOfBAPfDvXJk-9vxiQ' }}
          yesIWantToUseGoogleMapApiInternals
          options={MAP.options}
          onGoogleApiLoaded={({ map }: any) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }: any) => {
            setZoom(zoom);
            //@ts-ignore
            setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
          }}
        >
          {clusters &&
            clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } = cluster.properties;

              if (isCluster) {
                return (
                  //@ts-ignore
                  <Styled.ClusterMarker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                    <Spy />
                    <div
                      onClick={() => {
                        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                        //@ts-ignore
                        mapRef.current.setZoom(expansionZoom);
                        //@ts-ignore
                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      <span>{pointCount}</span>
                    </div>
                  </Styled.ClusterMarker>
                );
              }
              return (
                <Marker
                  key={`cluster-${cluster.properties.markerId}`}
                  lat={latitude}
                  lng={longitude}
                  cluster={cluster}
                ></Marker>
              );
            })}
        </GoogleMapReact>
        {isFetching && <SkeletonWrapper />}
      </Styled.MapWrapper>
    </>
  );
};
