import styled, { css } from 'styled-components';

const StyledCookie = styled.div(
  ({ theme }) => css`
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    position: fixed;
    padding: 10px;
    display: flex;
    z-index: 111111;
    flex-direction: column;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    ${theme.breakpoints.from('sm')} {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    a {
      color: rgb(85, 85, 85);
      margin-left: 0px;
      ${theme.breakpoints.from('sm')} {
        margin-left: 10px;
      }
    }

    button {
      color: rgb(0, 212, 200);
      border: 1px solid rgb(0, 212, 200);
      background-color: transparent;
      padding: 10px;
      margin-top: 10px;
      ${theme.breakpoints.from('sm')} {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  `,
);

StyledCookie.displayName = 'StyledCookie--Cookie';

export const Styled = {
  StyledCookie,
};
