import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth/useAuth';

import { Styled } from './Navbar.styles';

export const Navbar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Styled.StyledButton onClick={() => handleLogout()}>Wyloguj</Styled.StyledButton>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
