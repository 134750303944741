import { authMutations } from './auth/authActions.mutations';
import { authQueries } from './auth/authActions.queries';
import { detailsQueries } from './details/detailsActions.queries';
import { markersQueries } from './markers/markerActions.queries';

export const queries = {
  ...authQueries,
  ...markersQueries,
  ...detailsQueries,
} as const;

export type AxiosQueriesType = typeof queries;

export const mutations = {
  ...authMutations,
} as const;

export type AxiosMutationsType = typeof mutations;
