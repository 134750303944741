import styled, { css } from 'styled-components';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const SelectBase = styled(Select)(
  ({ theme }) => css`
    width: 100%;
    ${theme.breakpoints.from('md')} {
      width: 120px;
    }
    ${theme.breakpoints.from('lg')} {
      width: 142px;
    }
    &.MuiInputBase-root {
      height: 40px;

      ${theme.breakpoints.from('sm')} {
        height: 48px;
      }
    }

    .MuiFormLabel-root {
      font-size: 13px;
    }
  `,
);

SelectBase.displayName = 'SelectBase';

const SelectBaseLabel = styled(InputLabel)(
  ({ theme }) => css`
    &.MuiFormLabel-root {
      font-size: 13px;
      ${theme.breakpoints.from('sm')} {
        font-size: 13px;
      }
    }
  `,
);

SelectBaseLabel.displayName = 'SelectBase--Label';

export const Styled = {
  SelectBase,
  SelectBaseLabel,
};
