import { susolvkaCoords } from '../../fakeData';

export const MAP = {
  defaultZoom: 6,
  defaultCenter: susolvkaCoords,
  options: {
    maxZoom: 20,
    panControl: false,
    mapTypeControl: true,
  },
};
