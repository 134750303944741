import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppProviders } from 'providers/AppProviders';
import { AppRoutes } from 'routing/AppRoutes';
// import { worker } from 'api/mocks/mock-worker';
import { runMockServerWhenEnabled } from 'mocks/server';

const openReactQueryDevtools = false;

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
  // worker.start({ onUnhandledRequest: 'bypass' });
}

runMockServerWhenEnabled(false);

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <AppRoutes />
      {openReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root'),
);
