import styled, { css } from 'styled-components';

const InvestmentName = styled.h1(
  ({ theme }) => css`
    color: ${theme.colors.inputText};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    ${theme.breakpoints.from('sm')} {
      font-size: 28px;
      line-height: 42px;
    }
  `,
);

InvestmentName.displayName = 'Investment--Name';

export const Styled = {
  InvestmentName,
};
