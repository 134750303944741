import styled, { css } from 'styled-components';

import { easyMove } from 'theme/theme';

const MapWrapper = styled.div(
  () => css`
    width: 100%;
    height: calc(100vh - 110px - 70px);
    position: relative;
  `,
);

MapWrapper.displayName = 'MapWrapper';

const ClusterMarker = styled.div(
  ({ theme }) => css`
    transition: transform 0.3s;
    animation: ${easyMove} 0.3s;
    position: relative;

    svg {
      width: 35px;
      height: 40px;
    }

    span {
      font-size: 16px;
      color: ${theme.colors.white};
      position: absolute;
      top: 8px;
      width: 35px;
      text-align: center;
    }
  `,
);

ClusterMarker.displayName = 'MapWrapper--ClusterMarker';

const CustomMarker = styled.button(
  () => css`
    background: none;
    border: none;
  `,
);

CustomMarker.displayName = 'MapWrapper--CustomMarker';

export const Styled = {
  MapWrapper,
  ClusterMarker,
  CustomMarker,
};
