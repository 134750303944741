import React from 'react';

export const Spy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="48" viewBox="0 0 42 48">
      <g id="Group_25582" data-name="Group 25582" transform="translate(1213 5131)">
        <rect
          id="Rectangle_5769"
          data-name="Rectangle 5769"
          width="42"
          height="42"
          transform="translate(-1213 -5131)"
          fill="#ff5100"
        />
        <path
          id="Polygon_7"
          data-name="Polygon 7"
          d="M6,0l6,6H0Z"
          transform="translate(-1186 -5083) rotate(180)"
          fill="#ff5100"
        />
      </g>
    </svg>
  );
};
