import React from 'react';

import { ButtonProps } from './Button.types';
import { Styled } from './Button.styles';

export const Button = ({ title, onClick, testId }: ButtonProps) => {
  return (
    <Styled.StyledButton onClick={onClick} data-testid={testId}>
      {title}
    </Styled.StyledButton>
  );
};
