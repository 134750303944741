/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { QueryParamProvider } from 'use-query-params';

import { ClientsContext } from 'context/clients/ClientsContext';

import { useApiClients } from './apiClients';
import { ApiProviderProps } from './ApiProvider.types';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const RouteV6Adapter: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      //@ts-ignore
      push: ({ search, state }: Location) => navigate({ search }, { state }),
      //@ts-ignore
      replace: ({ search, state }: Location) => navigate({ search }, { replace: true, state }),
    }),
    [navigate],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return children({ history: adaptedHistory, location });
};

export const ApiProvider = ({ children }: ApiProviderProps) => {
  const apiClients = useApiClients();

  return (
    <ClientsContext.Provider value={apiClients}>
      <QueryClientProvider client={queryClient}>
        <QueryParamProvider ReactRouterRoute={RouteV6Adapter}>{children}</QueryParamProvider>
      </QueryClientProvider>
    </ClientsContext.Provider>
  );
};
