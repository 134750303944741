/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

import { CloseModal } from './closeModal/CloseModal';
import { ModalImageSliderProps } from './ModalImageSlider.types';
import { Styled } from './ModalImageSlider.styles';

import 'swiper/swiper-bundle.css';

export const ModalImageSlider = ({ open, handleClose, photoUrls, activeIndex }: ModalImageSliderProps) => {
  const [thumbsSwiperModal, setThumbsSwiperModal] = useState(null);

  const onClose = () => {
    setThumbsSwiperModal(null);
    handleClose();
  };

  return (
    <>
      <Styled.ModalWrapper data-testid="modal-image-slider">
        <Modal open={open} onClose={onClose} aria-labelledby="Galeria" aria-describedby="Galeria zdjęć">
          <>
            <CloseModal handleClose={onClose} />
            {photoUrls && (
              <Styled.ImageSliderWrapper>
                <Swiper
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiperModal }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  initialSlide={activeIndex}
                  className="bigImageModal"
                >
                  {photoUrls.map((photoUrl, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          loading="lazy"
                          src={`${photoUrl}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${photoUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          data-testid={`image-${index}`}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <Swiper
                  //@ts-ignore
                  onSwiper={setThumbsSwiperModal}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={6}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="thumbsModal"
                >
                  {photoUrls.map((photoUrl, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={`${photoUrl}`} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Styled.ImageSliderWrapper>
            )}
          </>
        </Modal>
      </Styled.ModalWrapper>
    </>
  );
};
