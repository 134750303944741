import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useLocale } from 'hooks/useLocale/useLocale';

import { loginSchema } from './Login.consts';

export const useLoginForm = () => {
  const { t } = useLocale();

  const resolver = yupResolver(loginSchema(t));

  const {
    register,
    control,
    setValue,
    trigger,
    watch,
    handleSubmit,
    formState: { errors, isValid, isValidating, touchedFields },
  } = useForm({ resolver, mode: 'onBlur' });

  return { register, errors, control, setValue, trigger, watch, isValid, isValidating, handleSubmit, touchedFields };
};
