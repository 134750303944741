import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { InfoButtonProps } from './InfoButton.types';
import { Styled } from './InfoButton.styles';

export const InfoButton = ({ msg }: InfoButtonProps) => {
  return (
    <>
      <Styled.InfoButtonWrapper>
        <Tooltip arrow title={<div dangerouslySetInnerHTML={{ __html: msg }} />}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="Group_25384" data-name="Group 25384" transform="translate(-981 -197)">
              <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                transform="translate(981 197)"
                fill="#fff"
                stroke="#d5d5dc"
                strokeWidth="1"
              >
                <circle cx="8" cy="8" r="8" stroke="none" />
                <circle cx="8" cy="8" r="7.5" fill="none" />
              </g>
              <text
                id="i"
                transform="translate(989 208)"
                fill="#011626"
                fontSize="9"
                fontFamily="Poppins-Medium, Poppins"
                fontWeight="500"
              >
                <tspan x="-1.188" y="0">
                  i
                </tspan>
              </text>
            </g>
          </svg>
        </Tooltip>
      </Styled.InfoButtonWrapper>
    </>
  );
};
