import { useEffect, useState } from 'react';

import { MEDIA } from 'assets/themes/breakpoints';

export const useMediaQuery = (query: string) => {
  const formattedQuery = query?.substr(MEDIA.length);
  const [matches, setMatches] = useState(window.matchMedia(formattedQuery).matches);

  useEffect(() => {
    const media = window.matchMedia(formattedQuery);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, formattedQuery]);

  return matches;
};
