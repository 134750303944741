import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTheme } from 'styled-components';

import { useMediaQuery } from 'hooks/useMediaQuery/useMediaQuery';
import { ModalImageSliderFloor } from '../modalImageSliderFloor/ModalImageSliderFloor';
import { InvestmentPopover } from '../investmentPopover/InvestmentPopover';

import { Styled } from './InvestmentTable.styles';
import { InvestmentTableProps } from './InvestmentTable.types';
import { DownloadIcon } from './downloadIcon/DownloadIcon';

export const InvestmentTable = ({ data }: InvestmentTableProps) => {
  const fromMobile = useMediaQuery(useTheme()?.breakpoints.from('sm'));
  const [rowData, setRowData] = useState(data);
  const [orderDirection, setOrderDirection] = useState('asc');

  const [floorImages, setfFoorImages] = useState<string[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const sortArray = (arr: any, orderBy: any) => {
    switch (orderBy) {
      case 'asc':
      default:
        return arr.sort((a: any, b: any) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
      case 'desc':
        return arr.sort((a: any, b: any) => (a.price < b.price ? 1 : b.price < a.price ? -1 : 0));
    }
  };

  const handleSortRequest = () => {
    setRowData(sortArray(data, orderDirection));
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleOpen = (images: string[]) => {
    setfFoorImages(images);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setfFoorImages([]);
  };

  const downloadImages = (images: string[]) => {
    if (!images.length) return;

    images.map((image, index) => {
      setTimeout(() => {
        fetch(image)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = `floor-${index}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      }, index * 1000);
    });
  };

  return (
    <>
      <Styled.InvestmentTableContainer>
        {fromMobile ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {data && !data.length && (
                  <caption>
                    <Styled.InvestmentTableEmpty>Brak danych do wyświetlenia</Styled.InvestmentTableEmpty>
                  </caption>
                )}
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nr ref.</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Etap</TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Cena
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Cena m²
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Metraż
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Pokoje
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Piętro
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Oddanie</TableCell>
                    <TableCell align="center">Rzut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((row, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center">{row?.referenceNumber}</TableCell>
                        <TableCell align="center">{row?.status}</TableCell>
                        <TableCell align="center">{row?.stage}</TableCell>
                        <TableCell align="center">{row?.price} zł</TableCell>
                        <TableCell align="center">{row?.squareMeterPrice} zł/m²</TableCell>
                        <TableCell align="center">{row?.area} m²</TableCell>
                        <TableCell align="center">{row?.roomsCount}</TableCell>
                        <TableCell align="center">{row?.floor}</TableCell>
                        <TableCell align="center">{row?.finishTerm}</TableCell>
                        <TableCell align="center">
                          {row?.floorPlanUrls.length > 0 ? (
                            <>
                              <InvestmentPopover floorPlanUrls={row?.floorPlanUrls} handleOpen={handleOpen} />
                            </>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nr ref.</TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Cena
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Metraż
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Pokoje
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" onClick={handleSortRequest}>
                      <TableSortLabel active={true} direction={orderDirection as any}>
                        Piętro
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Rzut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((row, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center">{row?.referenceNumber}</TableCell>
                        <TableCell align="center">{row?.price} zł</TableCell>
                        <TableCell align="center">{row?.area} m²</TableCell>
                        <TableCell align="center">{row?.roomsCount}</TableCell>
                        <TableCell align="center">{row?.floor}</TableCell>
                        <TableCell align="center">
                          <Styled.InvestmentTableSvgWrapper onClick={() => handleOpen(row?.floorPlanUrls)}>
                            <DownloadIcon />
                          </Styled.InvestmentTableSvgWrapper>
                          <Styled.InvestmentTableDownload onClick={() => downloadImages(row?.floorPlanUrls)}>
                            Pobierz
                          </Styled.InvestmentTableDownload>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Styled.InvestmentTableDescription>
          Dane dotyczące lokali przedstawiają możliwie najbardziej aktualne wartości uzyskane przez usługodawcę w ramach
          współpracy z podmiotami odpowiedzialnymi za ich sprzedaż. Szczegóły w{' '}
          <a href="https://propone.pl/policies/terms-of-service" target="_blank" rel="noreferrer">
            Regulaminie
          </a>
          . Powyższe informacje nie stanowią oferty handlowej w rozumieniu art. 66 § 1 Kodeksu Cywilnego.
        </Styled.InvestmentTableDescription>
        <ModalImageSliderFloor open={isOpen} handleClose={handleClose} photoUrls={floorImages} />
      </Styled.InvestmentTableContainer>
    </>
  );
};
