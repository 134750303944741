import { Route, Routes } from 'react-router-dom';

import { Layout } from 'app/layout/Layout';
import { GoogleMapContainer } from 'app/googleMap/GoogleMapContainer';
import { LoginContainer } from 'app/login/LoginContainer';
import { PointDetailsContainer } from 'app/pointDetails/PointDetailsContainer';
import { Navbar } from 'app/layout/navbar/Navbar';

import { AppRoute } from './AppRoute.enum';
import { PrivateRoute } from './privateRoute/PrivateRoute';

export const AppRoutes = () => (
  <Routes>
    <Route path={AppRoute.home} element={<LoginContainer />} />
    <Route path={AppRoute.home} element={<Layout />}>
      <Route
        path={AppRoute.map}
        element={
          <PrivateRoute>
            <Navbar />
            <GoogleMapContainer />
          </PrivateRoute>
        }
      />
      <Route
        path={AppRoute.pointDetails}
        element={
          <PrivateRoute>
            <Navbar />
            <PointDetailsContainer />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<LoginContainer />} />
    </Route>
  </Routes>
);
