import { MarkersResponse } from 'api/actions/markers/markerActions.types';

export const markers: MarkersResponse = [
  {
    id: '29BGqx',
    investmentName: 'Osiedle Bursztynowy Zakątek Residence',
    developerName: 'ABC Development',
    location: 'Warszawa, Praga Południe',
    priceMin: 890000,
    priceMax: 2356000,
    currency: 'zł',
    pricePerMeterMin: 10833,
    pricePerMeterMax: 12500,
    areaMin: 36,
    areaMax: 120,
    roomMin: 1,
    roomMax: 5,
    latitude: 50.2527835,
    longitude: 19.0389323,
    photoUrl:
      'https://cdn.shopify.com/s/files/1/0677/4017/2604/products/Jagiellonska-Widoknaelewacje_700x.jpg?v=1670365040',
  },
  {
    id: 'Pqbzq8',
    investmentName: 'Osiedle LATTE',
    developerName: 'UniDevelopment',
    location: 'Warszawa, Bielany',
    priceMin: 560000,
    priceMax: 3500000,
    currency: 'zł',
    pricePerMeterMin: 8900,
    pricePerMeterMax: 16500,
    areaMin: 50,
    areaMax: 145,
    roomMin: 2,
    roomMax: 6,
    latitude: 51.2527835,
    longitude: 21.0389323,
    photoUrl:
      'https://cdn.shopify.com/s/files/1/0677/4017/2604/products/2020_mipim_pawilon_02a_m_small_80b8981e-732f-451f-9fe7-d9eb874d8648_700x.jpg?v=1670347544',
  },
];
