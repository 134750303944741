import { useContext } from 'react';

import { ToastContext } from 'context/toast/toastContext/ToastContext';

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('ToastContext is unavailable, make sure you are using ToastContextControlle');
  }

  return context;
};
