import { AxiosInstance } from 'axios';
import { stringify } from 'qs';

import { MarkersResponse, GetMarkersArg, SinglePoint, GetSingleArg, GetTermsResponse } from './markerActions.types';

export const markersQueries = {
  getMarkers:
    (client: AxiosInstance) =>
    async ({
      location = '',
      startPrice = '',
      endPrice = '',
      startSquare = '',
      endSquare = '',
      termId = '',
    }: GetMarkersArg) => {
      const queryParams = stringify(
        { location, termId: termId, startPrice, endPrice, startSquare, endSquare },
        { addQueryPrefix: true },
      );
      return (await client.get<MarkersResponse>(`/investment/${queryParams}`)).data;
    },
  getSingleMarkerDetails:
    (client: AxiosInstance) =>
    async ({ id }: GetSingleArg) => {
      return (await client.get<SinglePoint>(`/investment/${id}/map`)).data;
    },
  getTerms: (client: AxiosInstance) => async () => {
    return (await client.get<GetTermsResponse>('/dictionary/term-types')).data;
  },
};
