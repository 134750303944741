/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useAuth } from 'hooks/useAuth/useAuth';

import { Login } from './Login';
import { useLoginForm } from './Login.utils';

export const LoginContainer = () => {
  const { register, errors, handleSubmit, touchedFields } = useLoginForm();

  const { loginMutation } = useAuth();

  const onSubmit = (data: any) => {
    return loginMutation(data?.username, data?.password);
  };

  return (
    <>
      <Login
        handleSubmit={handleSubmit}
        onFormSubmit={onSubmit}
        register={register}
        errors={errors}
        touchedFields={touchedFields}
        isLoading={false}
      />
    </>
  );
};
