/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const ClusterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="48" viewBox="0 0 66 48">
      <g id="Group_25618" data-name="Group 25618" transform="translate(-852 -352)">
        <g id="Group_25617" data-name="Group 25617" transform="translate(31 7)">
          <rect
            id="Rectangle_5767"
            data-name="Rectangle 5767"
            width="66"
            height="42"
            transform="translate(821 345)"
            fill="#ff5100"
          />
        </g>
        <path
          id="Polygon_6"
          data-name="Polygon 6"
          d="M6,0l6,6H0Z"
          transform="translate(891 400) rotate(180)"
          fill="#ff5100"
        />
      </g>
    </svg>
  );
};
